// Dashboard.js
import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import axios from 'axios';
import LoadingSpinner from '../../components/LoadingSpinner';
import { useNavigate } from "react-router-dom";
import PDFExport from '../../components/PDFExport';
import ExcelExport from '../../components/ExcelExport';
import MovableSpluDataOverlay from '../../components/MovableSpluDataOverlay';

function SpluData () {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [showPDF, setShowPDF] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [searchData, setsearchData] = useState("");
  
  const [readOnlyInputValue, setreadOnlyInputValue] = useState(true);
  const [overlayData, setOverlayData] = useState([]);
  const [sortBy, setSortBy] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');

  const onPopulateOverlayControl = async (device_id, splu_id, status) => {
    setLoading(true);
    try {
      const token = localStorage.getItem('user-token');
      if (!token) {
        console.error('Token not found in localStorage.');
        localStorage.clear();
        navigate('/auth/login');
        return;
      }
      
      const response = await axios.post('https://spluv2.listrikkita.net/api/detail-spludata', { id: device_id, slot: splu_id }, {
        headers: {
          Authorization: `${token}`,
        },
      });
      
      if (response.data.code === 200 && response.data.status) {
        const resultData = response.data.data;  
        const overlayId = Date.now();
        const newOverlayData = { 
            id: overlayId, 
            Alias: resultData.device_name,
            ID: resultData.splu_sn.substring(resultData.splu_sn.length - 3).substring(0, 2),
            Code: resultData.splu_sn,
            Voltage: resultData.voltage,
            Current: resultData.current,
            Energy: resultData.energy,
            PowerFactor: resultData.power_factor,
            Status: resultData.is_used,
            Internet: resultData.is_online,
            Permission: resultData.is_enabled,
            LastUpdate: resultData.timestamp,
            UserName: resultData.user_name,
            UserMail: resultData.user_email,
            UsageEnergy: resultData.usage_energy,
            readOnlyInput: status,
            loc_lat: resultData.latitude,
            loc_lng: resultData.longtitude,
            map_loc: resultData.device_name + "<br>Slot ID: " + resultData.splu_sn.substring(resultData.splu_sn.length - 3).substring(0, 2) + "<br>" + resultData.location,
            device_id: device_id,
            slot_id: splu_id
        };
        setOverlayData(prevOverlayData => [...prevOverlayData, newOverlayData]);
      }
      else if ((response.data.code === 401 || response.data.code === 403) && !response.data.status) {
        localStorage.clear();
        navigate('/auth/login');
        return;
      }
      else {
        alert('data not found');
      }
    } catch (error) {
      alert('unexpected exception occured please try again later');
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseClick = (id) => {
    // Remove the overlay data object with the given id from the array
    const updatedOverlayData = overlayData.filter((overlay) => overlay.id !== id);
    setOverlayData(updatedOverlayData);
    setLoading(false);
  };
  
  const updateOverlayData = (id, newData) => {
    setOverlayData(prevOverlayData => {
      const updatedData = prevOverlayData.map(overlay => {
        if (overlay.id === id) {
          // Merge the existing overlay data with the new data
          return { ...overlay, ...newData };
        }
        return overlay;
      });
      return updatedData;
    });
  };
  
  const handleSort = (columnName) => {
    if (columnName === sortBy) {
      // Jika kolom yang sama diklik lagi, ubah arah pengurutan
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      // Jika kolom yang berbeda diklik, atur kolom baru sebagai kolom yang diurutkan
      setSortBy(columnName);
      setSortDirection('asc');
    }
  };
  
  const renderTableRows = () => {
    let sortedData = [...tableData];
    if (sortBy) {
      sortedData.sort((a, b) => {
        if (sortDirection === 'asc') {
          if (typeof a[sortBy] === 'string') {
            return a[sortBy].localeCompare(b[sortBy]);
          } else {
            return a[sortBy] - b[sortBy];
          }
        } else {
          if (typeof a[sortBy] === 'string') {
            return b[sortBy].localeCompare(a[sortBy]);
          } else {
            return b[sortBy] - a[sortBy];
          }
        }
      });
    }
    
    return sortedData.map((row, index) => (
      <tr key={index}>
        <td>{row.device_name}</td>
        <td>{row.splu_sn.substring(row.splu_sn.length - 3).substring(0, 2)}</td>
        <td className={row.is_online === 1 ? 'table-success' : 'table-danger'}>{row.is_online === 1 ? 'Online' : 'Offline'}</td>
        <td>{row.is_used === 1 ? 'In Use' : 'Stand by'}</td>
        <td>{row.last_user}</td>
        <td>{row.is_enabled === 1 ? 'Allow / Enabled' : 'Disabled'}</td>
        <td>
          <button hidden className="btn btn-sm btn-primary mr-2" onClick={() => onPopulateOverlayControl(row.device_id, row.splu_id, false)}>
            <i className="bi bi-pencil"></i> Edit
          </button>
          <button className="btn btn-sm btn-success ml-2" onClick={() => onPopulateOverlayControl(row.device_id, row.splu_id, true)}>
            <i className="bi bi-info-circle"></i> Detail
          </button>
        </td>
      </tr>
    ));
  }
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('user-token');
        if (!token) {
          console.error('Token not found in localStorage.');
          localStorage.clear();
          navigate('/auth/login');
          return;
        }
        
        const response = await axios.get('https://spluv2.listrikkita.net/api/base-spludata', {
          headers: {
            Authorization: `${token}`,
          },
        });
        
        if (response.data.code === 200 && response.data.status) {
          setTableData(response.data.data);
        }
        else if ((response.data.code === 401 || response.data.code === 403) && !response.data.status) {
          localStorage.clear();
          navigate('/auth/login');
          return;
        }
        else {
          alert('data not found');
        }
      } catch (error) {
        alert('unexpected exception occured please try again later');
        console.error('Error:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    
    setTimeout(() => {
      setLoading(false);
    }, 20000); 
  }, []);
  
  
  const getsearchData = async () => {
   setLoading(true);  
    try {
      const token = localStorage.getItem('user-token');
      if (!token) {
        console.error('Token not found in localStorage.');
        localStorage.clear();
        navigate('/auth/login');
        return;
      }
      
      const response = await axios.post('https://spluv2.listrikkita.net/api/search-splu-data', {
          "search-value": searchData
        }, {
        headers: {
          Authorization: `${token}`,
        },
      });
      
      if (response.data.code === 200 && response.data.status) {
        // Set data tabel baru
        setTableData(response.data.data);
      }
      else if ((response.data.code === 401 || response.data.code === 403) && !response.data.status) {
        localStorage.clear();
        navigate('/auth/login');
        return;
      }
      else {
        alert('data not found');
      }
    } catch (error) {
      alert('unexpected exception occured please try again later');
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };
  
  const AnyReactComponent = ({ text }) => <div>{text}</div>;
  const defaultProps = {
    center: {
      lat: -8.322190205614577,
      lng: 115.19236986917853
    },
    zoom: 12
  };
  return (
    <React.Fragment>
      {showPDF && <PDFExport tableData={tableData} />}
      {loading && <LoadingSpinner />}
      <div className="container mt-3">
        <div className="row" style={{ textAlign: 'left', color: 'black', marginBottom: '10px', borderBottom: '2px solid black', fontSize: '28px', paddingBottom: '12px', paddingTop: '12px', paddingRight: '12px' }}>
          <h1>SPLU Monitoring</h1>
        </div>
        <div className="row mb-3">
          <div className="col-md-6">
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="search-button"
                value={searchData}
                onChange={(e) => setsearchData(e.target.value)}
              />
              <button className="btn btn-outline-secondary" type="button" onClick={getsearchData} id="search-button">
                <i className="bi bi-search"></i>
              </button>
            </div>
          </div>
          <div className="col-md-6 text-end">
            <button hidden className="btn btn-primary mx-2">
              <ExcelExport tableData={tableData} />
            </button>
            <button hidden className="btn btn-primary mx-2" onClick={() => setShowPDF(!showPDF)}>Export PDF</button>
          </div>
        </div>
  
        <table className="table" style={{ marginBottom: '100px', width: '100%', tableLayout: 'fixed' }}>
          <thead>
            <tr>
              <th scope="col">SPLU Name</th>
              <th scope="col">SPLU ID</th>
              <th style={{ color: '#376DFF', cursor: 'pointer' }} scope="col" onClick={() => handleSort('is_online')}>Internet Status</th>
              <th style={{ color: '#376DFF', cursor: 'pointer' }} scope="col" onClick={() => handleSort('is_used')}>Used Status</th>
              <th scope="col">Last Transaction</th>
              <th scope="col">SPLU PERMISSION</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {tableData.length > 0 ? (
              renderTableRows()
            ) : (
              <tr>
                <td colSpan="7">No data found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      
      {overlayData.map((overlay) => (
        <MovableSpluDataOverlay
          key={overlay.id}
          // Pass the data from the state to the individual overlay
          Alias={overlay.Alias}
          ID={overlay.ID}
          Code={overlay.Code}
          Voltage={overlay.Voltage}
          Current={overlay.Current}
          Energy={overlay.Energy}
          PowerFactor={overlay.PowerFactor}
          Status={overlay.Status}
          Internet={overlay.Internet}
          Permission={overlay.Permission}
          LastUpdate={overlay.LastUpdate}
          UserName={overlay.UserName}
          UserMail={overlay.UserMail}
          UsageEnergy={overlay.UsageEnergy}
          closeFunction={() => handleCloseClick(overlay.id)}
          readOnlyInput={overlay.readOnlyInput}
          loc_lat={parseFloat(overlay.loc_lat)}
          loc_lng={parseFloat(overlay.loc_lng)}
          map_loc={overlay.map_loc}
          device_id={overlay.device_id}
          slot_id={overlay.slot_id}
          // Function to update the overlay's data
          updateOverlay={(newData) => updateOverlayData(overlay.id, newData)}
        />
      ))}
    </React.Fragment>
  );
}

export default SpluData;
